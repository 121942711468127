export default class Modal {
    constructor() {
        this.init();
    }

    init() {
        this.closeModal();
    }

    closeModal() {
        $('.modal').on('click', function(e) {
            if ($(e.target).parents('.modal__content').length) {
                return;
            }
            $('.modal').removeClass('active');
        });

        $('.modal__close').on('click', function(e) {
            $('.modal').removeClass('active');
        });
    }
}
