import slick from 'slick-carousel';

export default class Partners {
    constructor() {
        this.init();
    }

    init() {
        $('.partners__list').slick({
            infinite: true,
            autoplay : true,
            autoplaySpeed : 2000,
            mobileFirst : true,
            responsive: [{
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 6,
                        infinite: true
                    }
                }, 
                {
                    breakpoint: 780,
                    settings: {
                        slidesToShow: 4,
                    }
                }, 
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                    }
                }, 
                {
                    breakpoint: 120,
                    settings: {
                        slidesToShow: 2,
                    }
                }]
        });
    }
}
