import Menu from './components/menu';
import Partners from './components/partners';
import Newslist from './components/newslist';
import Linklist from './components/linklist';
import Testimonials from './components/testimonials';
import Faq from './components/faq';
import Modal from './components/modal';
import Pagination from './components/pagination';

export default class Theme {
    constructor() {
        this.init();
        this.bindEvents();
    }

    init() {
        this.menu = new Menu();
        this.partners = new Partners();
        this.newslist = new Newslist();
        this.linklist = new Linklist();
        this.testimonials = new Testimonials();
        this.faq = new Faq();
        this.modal = new Modal();
        this.pagination = new Pagination();
    }

    bindEvents() {
    }
}

document.addEventListener("DOMContentLoaded", () => {
    const theme = new Theme();
});