import slick from 'slick-carousel';

export default class Testimonials {
    constructor() {
        this.init();
    }

    init() {
        $('.testimonials__list').slick({
            infinite: true,
            autoplay : false,
            autoplaySpeed : 5000,
            mobileFirst : true,
            dots: true,
            fade : true,
            arrows: true,
            responsive: [{
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        infinite: true
                    }
                }]
        });
    }
}
