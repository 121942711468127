export default class Faq {
    constructor() {
        this.init();
    }

    init() {
       $('.faq__question').on('click', function(e) {
            $(this).parent().toggleClass('active');
        });
    }
}
