export default class Pagination {
    constructor() {
        this.pagination = document.querySelector('.pagination');
        if (this.pagination) {
            this.init();
        }
    }

    init() {
        this.newslist = document.querySelector('.newslist');
        this.currentPage = parseInt(this.pagination.dataset.currentPage);
        this.maxPages = parseInt(this.pagination.dataset.maxPages);
        this.catId = this.pagination.dataset.catId;
        this.isLoading = false;

        this.prevButton = this.pagination.querySelector('.pagination__btn--prev');
        this.nextButton = this.pagination.querySelector('.pagination__btn--next');
        this.currentSpan = this.pagination.querySelector('.pagination__current');

        this.bindEvents();
    }

    bindEvents() {
        this.prevButton.addEventListener('click', () => this.loadPage('prev'));
        this.nextButton.addEventListener('click', () => this.loadPage('next'));
    }

    async loadPage(direction) {
        if (this.isLoading) return;

        const newPage = direction === 'prev' ? this.currentPage - 1 : this.currentPage + 1;
        
        if (newPage < 1 || newPage > this.maxPages) return;

        this.isLoading = true;
        this.pagination.classList.add('loading');

        try {
            const response = await fetch(`${window.location.origin}/wp-admin/admin-ajax.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: `action=load_more_posts&page=${newPage}&cat=${this.catId}`,
            });

            if (!response.ok) throw new Error('Network response was not ok');

            const data = await response.json();

            if (data.success) {
                this.newslist.innerHTML = data.data.html;
                this.currentPage = newPage;
                this.currentSpan.textContent = this.currentPage;

                // Mise à jour des états des boutons
                this.prevButton.disabled = this.currentPage <= 1;
                this.nextButton.disabled = this.currentPage >= this.maxPages;

                // Mise à jour de l'URL
                const url = new URL(window.location);
                url.searchParams.set('paged', this.currentPage);
                window.history.pushState({}, '', url);

                // Scroll en haut de la liste
                this.newslist.scrollIntoView({ behavior: 'smooth' });
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            this.isLoading = false;
            this.pagination.classList.remove('loading');
        }
    }
}
