import hoverIntent from 'hoverintent';

export default class Menu {
    constructor() {
        this.init();
    }

    init() {
        if ($('.header__menu').length > 0) {
            this.mobileMode = false;
            this.checkMenuMobile();
            if (!this.mobileMode) {
                this.activeFirstLevel();
                this.menuHover();
                this.subMenuHover();
            } else {
                this.burgerMenu();
                this.createNavMenu();
                this.bindEventsMobile();
            }
        }
    }

    burgerMenu() {
        const burger = document.querySelector('#js-burger');
        const menu = document.querySelector('.header__menu');

        burger.addEventListener('click', () => {
            burger.classList.toggle('active');
            menu.classList.toggle('active');
            $('html').toggleClass('menu-open');
        });
    }   

    checkMenuMobile() {
        if ($(window).width() < 981) {
            this.mobileMode = true;
        } else {
            this.mobileMode = false;
        }

        $(window).resize(() => {
            if ($(window).width() < 981 && !this.mobileMode) {
                this.mobileMode = true;
                this.createNavMenu();
                this.burgerMenu();
                this.bindEventsMobile();
            } else if ($(window).width() >= 981 && this.mobileMode) {
                this.mobileMode = false;
                this.removeNavMenu();
            }
        });
    }

    createNavMenu() {
        if (this.mobileMode) {
            let template = document.getElementById('mobile-nav');
            let clone = template.content.cloneNode(true);

            $('.header__logo').clone().prependTo('.header__menu');
            $('.header__menu').append(clone);
        }
    }

    removeNavMenu() {
        if (!this.mobileMode) {
            $('.header__menu .header__logo').remove();
            $('.header__menu .menu-mobile-container').remove();
            $('.header__menu .menu-mobile-address').remove();
            $('.header__menu .menu-mobile-qualite').remove();
            //$('.header__menu').find('.menu').remove();
        }
    }

    bindEventsMobile() {
        $('.menu-item-has-children>a').each(function(){
           $(this).append('<span class="open-sub"><i class="icon-arrow-right"></i></span>');
        });
        $('.open-sub').on('click', function(e) {
            e.preventDefault();
            $(this).parent().parent().toggleClass('active');
        });
    }

    menuHover() {
        var menuItems = document.querySelectorAll('.menu>.menu-item');
        menuItems.forEach(item => {
            hoverIntent(item,
            function() {
                menuItems.forEach(menuItem => menuItem.classList.remove('active'));
                item.classList.add('active');
            },function() {
                menuItems.forEach(menuItem => menuItem.classList.remove('active'));
            });
        });
    }

    subMenuHover() {
        var subMenuItems = document.querySelectorAll('.menu>.menu-item>.sub-menu>.menu-item');
        subMenuItems.forEach(item => {
            hoverIntent(item,
            function() {
                const menuItems = document.querySelectorAll('.menu>.menu-item>.sub-menu>.menu-item');
                menuItems.forEach(menuItem => menuItem.classList.remove('active'));

                const subMenu = document.querySelectorAll('.sub-menu');
                subMenu.forEach(subMenu => subMenu.classList.remove('active'));

                item.classList.add('active');
                item.querySelector('.sub-menu').classList.add('active');
            }, function() {
            });
        });
    }

    activeFirstLevel() {
        const firstLevelItems = document.querySelector('.menu-item.menu-item-has-children>.sub-menu>.menu-item:first-child');   
        const firstLevelSubMenu = firstLevelItems.querySelector('.sub-menu');

        firstLevelItems.classList.add('active');
        firstLevelSubMenu.classList.add('active');
    }
}